.userList {
  display: grid;
  width: 90%;
  justify-content: center;
  align-content: center;
  grid: auto-flow / repeat(auto-fill, minmax(200px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}
.userList .userList__listSong {
}
.userList .userList__listSong--filtered {
  display: none;
}
@media (max-width: 500px) {
  .userList {
    grid: auto-flow / repeat(auto-fill, minmax(100px, 1fr));
  }
}
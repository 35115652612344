.app {
  /*text-align: center;*/
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error {
  color: #e55;
}
.success {
  color: #3c3;
}

.form {
  display: flex;
  flex-direction: column;
}
.form__input {
  display: block;
  width: 100%;
  font-size: 1.2em;
  height: 2em;
  padding: 0 1em;
  border: 4px solid #aaa;
}
.dark-mode--activated .form__input {
  border: 4px solid #888;
  color: white;
  background-color: #3a3a3a;
}
.dark-mode--activated .form__input::placeholder {
  color: #888;
}
.form__label {
  text-align: center;
}
.form__submit {
  margin: 1rem 0;
}

.form__button {
  cursor: pointer;
  user-select: none;
  padding: 1em 2em;
}
.form__button:hover {
}
.dark-mode--activated .form__button {
  background-color: #555;
  border: 2px solid #999;
  color: #fff;
}
.dark-mode--activated .form__button:hover {
  background-color: #777;
  border: 2px solid #bbb;
  color: #fff;
}
.form__button--submit {
  font-weight: bold;
}

.button {
  font-size: 1em;
  background-color: #999;
  border: 2px solid #777;
  color: #fff;
}
.dark-mode--activated
.button {
  background-color: #555;
  color: #fff;
}
.button:hover {
  background-color: #777;
  border: 2px solid #bbb;
  color: #fff;
}
.dark-mode--activated
.button:hover {
  background-color: #777;
  color: #fff;
}
.loading_panel {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  font-size: 5em;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  color: #777;
}
.loading_panel--fullscreen {
  position: absolute;
  min-height: 90vh;
}

.username_form {
  margin: 10vh 0;
  width: 50vw;
}
.username_form__input {
  margin: 0.5rem 0 2rem 0;
}
.dark-mode--activated .username_form__input {
}
.username_form__label {
}
.username_form__submit {
}
.username_form .form__button {
  margin: 1rem 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #cacaca; */
  background-color: #eee;
  color: #222;
}

body.dark-mode--activated {
  background-color: #2a2a2a;
  color: white;
}

body.LOCALDEV {
  border-top: 1px solid red;
}

button {
  color: inherit;
  background-color: inherit;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  transition: background-color 1s ease; /* dude wtf */
}

code {
  font-family: Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}
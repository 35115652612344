.button {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.iconContainer {
  cursor: pointer;
  position: relative;
  height: max(3.2rem, 1vw);
  width: max(3.2rem, 1vw);
  overflow: hidden;
  animation: glister 4s ease infinite alternate;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #666;
}
:global(.dark-mode--activated)
.iconContainer {
  border: 1px solid #bbb;
}

.icon {
  transition: top 2s ease, left 2s ease;
  display: block;
  width: 100%;
  position: relative;
}

.sun {
  composes: icon;
  filter: invert(85%) sepia(27%) saturate(906%) hue-rotate(348deg) brightness(105%) contrast(102%); /* #ffe463 */
  /* transform: translate(0%, -100%); */
  top: -100%;
  left: 0%;
}

:global(.dark-mode--activated)
.sun {
  /* transform: translate(100%, 0%); */
  top: 0%;
  left: 100%;
}

.moon {
  composes: icon;
  filter: invert(97%) sepia(20%) saturate(7474%) hue-rotate(293deg) brightness(144%) contrast(92%); /* #f7f4e6 */
  /* transform: translate(-100%, 100%); */
  top: 100%;
  left: -100%;
}
:global(.dark-mode--activated)
.moon {
  /* transform: translate(0%, 0%); */
  top: 0%;
  left: 0%;
}

@media (prefers-reduced-motion: no-preference) {
  .moon {
    animation: wiggle 2s infinite ease-in-out alternate;
  }
  .sun {
    animation: spin 10s infinite linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes wiggle {
  from {
    transform: rotate(-15deg);
  }
  to {
    transform: rotate(15deg);
  }
}
@keyframes glister {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(-30deg);
  }
}

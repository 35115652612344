/* Filters made with https://codepen.io/sosuke/pen/Pjoqqp */
.header {
  width: 100%;
}

.header .heading {
  text-align: center;
  margin: 0 0;
  margin-bottom: 1rem;
}

.headerUtilities {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 min(1rem, 2vw);
  opacity: 0.5;
}

.filterSortContainer {
  flex-direction: row;
  align-items: center;
  width: 11em;
  margin-top: min(1rem, 2vh);
}

.pinForm {
  composes: form from global;
  flex-direction: row !important;
  align-items: center !important;
  margin-top: min(1rem, 2vh);
}

.pinFormInput {
  composes: form__input from global;
  width: 11em !important; /* :( */
}

.pinFormUnlocked
.pinFormInput {
  background-color: #5c5;
}
:global(.dark-mode--activated)
.pinFormUnlocked
.pinFormInput {
  background-color: #252;
}

.pinFormIcon {
  height: 1.5rem;
  margin: 0 0.5rem;
  padding: 0 1px;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(218deg) brightness(97%) contrast(103%); /* #000 */
}
:global(.dark-mode--activated)
.pinFormIcon {
  filter: invert(100%) sepia(0%) saturate(7493%) hue-rotate(299deg) brightness(100%) contrast(103%); /* #fff */
}

.pinFormIconUnlocked {
  composes: pinFormIcon;
  filter: invert(53%) sepia(13%) saturate(2689%) hue-rotate(71deg) brightness(99%) contrast(82%); /* #3a3 */
}
:global(.dark-mode--activated)
.pinFormIconUnlocked {
  filter: invert(60%) sepia(42%) saturate(599%) hue-rotate(71deg) brightness(103%) contrast(98%); /* #5c5 */
}

.errorMessage {
  composes: error from global;
  text-align: center;
  margin: 0.5rem 0;
  font-size: 0.8em;
  /* color: red; */
  height: 1em;
}
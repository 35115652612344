.header {
  font-size: 1.2em;
  height: min(6rem, 10vh);
  min-height: 4rem;
  display: flex;
  flex-direction: row;
  background-color: #aaa;
}
.dark-mode--activated .header {
  background-color: #444;
}

.header__link {
  display: flex;
  height: 70%;
  align-self: center;
  align-items: center;
  margin: 0 2vw;
  padding: 0 1rem;
  border: #1f1f1f 2px solid;
  color: white;
  background-color: #888;
  white-space: nowrap;
  overflow: hidden;
}
.header__link:hover {
  color: white;
  background-color: #777;
}

.header__theme_button {
  margin-right: max(10px, 1vw);
}

.header__link__text {
  text-align: center;
}
.header__theme_button {
  margin-left: auto;
}

.header__logo {
  margin-right: 1vw;
}
.header__logo img {
  width: min(6rem, 10vh); /*Same as app_header height*/
  min-width: 4rem;
  filter: drop-shadow(0px 0px 10px white);
}
.dark-mode--activated
.header__logo img {
  filter: drop-shadow(0px 0px 10px #555);
}

@media (prefers-reduced-motion: no-preference) {
  .header__logo img {
    animation: logo_breath infinite 2s linear both;
  }
}

@keyframes logo_breath {
  0%,100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
}
